import { yupResolver } from '@hookform/resolvers/yup';
import { nanoid } from 'nanoid';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';

// import { schema } from './Abas/Geral/schema';
import {
  IBalanca,
  IFormBalanca,
  IFormEtiqueta,
  IFormRegraLoja,
  ISelectType,
  UseFormProps,
} from './types';
import { schema } from './Validations';

type RegraLojaContextData = {
  formRegraLoja: IFormRegraLoja;
  changeFormRegraLoja: (value: IFormRegraLoja) => void;
  setFormRegraLoja: (value: React.SetStateAction<IFormRegraLoja>) => void;
  formEtiqueta: IFormEtiqueta;
  changeFormEtiqueta: (value: IFormEtiqueta) => void;
  handleClearFormEtiqueta: () => void;
  formBalanca: IFormBalanca;
  changeFormBalanca: (value: IFormBalanca) => void;
  handleClearFormBalanca: () => void;
  handleClearForms: () => void;
  aba: any;
  changeAba: (tab: any) => void;
  abaPeriferico: any;
  changeAbaPeriferico: (tab: any) => void;
  initInicializado: boolean;
  toggleInitInicializado: (value: boolean) => void;
  isUpdate: boolean;
  toggleIsUpdate: (value: boolean) => void;
  balancas: IBalanca[];
  setBalancas: (value: IBalanca[]) => void;
  balancasInsert: IBalanca[];
  setBalancasInsert: (value: IBalanca[]) => void;
  balancasUpdate: IBalanca[];
  setBalancasUpdate: (value: IBalanca[]) => void;
  balancasDelete: IBalanca[];
  setBalancasDelete: (value: IBalanca[]) => void;
  formTela: UseFormProps;
  tipoTotalizadoresBalanca: ISelectType[];
  qntdDigts: ISelectType[];
};

export const RegraLojaContext = createContext({} as RegraLojaContextData);

interface RegraLojaContextProviderProps {
  children: ReactNode;
}

export function RegraLojaContextProvider({
  children,
}: RegraLojaContextProviderProps) {
  const [isUpdate, setIsUpdate] = useState(false);
  const [initInicializado, setInitInicializado] = useState(false);
  const [aba, setAba] = useState<any>('geral');
  const [abaPeriferico, setAbaPeriferico] = useState<any>('etiquetas');
  const [balancas, setBalancas] = useState<IBalanca[]>([]);
  const [balancasInsert, setBalancasInsert] = useState<IBalanca[]>([]);
  const [balancasUpdate, setBalancasUpdate] = useState<IBalanca[]>([]);
  const [balancasDelete, setBalancasDelete] = useState<IBalanca[]>([]);

  const tipoTotalizadoresBalanca = [
    {
      value: 0,
      label: 'Valor Total',
    },
    {
      value: 1,
      label: 'Quantidade Total',
    },
  ];

  const qntdDigts = [
    {
      value: 0,
      label: '4',
    },
    {
      value: 1,
      label: '5',
    },
    {
      value: 2,
      label: '6',
    },
  ];

  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    clearErrors,
    setError,
    resetField,
  };

  useEffect(() => {
    setValue('tipo_regime_pis_cofins', { label: 'Não cumulativo ', value: 0 });
    setValue('cst_pis_cof_entrada', {
      value: '50',
      label:
        '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
    });
    setValue('cst_pis_cof_saida', {
      value: '01',
      label: '01 - Operação Tributável com Alíquota Básica',
    });
    setValue('cst_pis_cof_ent_aliq_zero', {
      value: '73',
      label: '73 - Operação de Aquisição a Alíquota Zero',
    });

    setValue('cst_pis_cof_saida_aliq_zero', {
      value: '06',
      label: '06 - Operação Tributável a Alíquota Zero',
    });
    setValue('cst_pis_cof_ent_monof', {
      value: '70',
      label: '70 - Operação de Aquisição sem Direito a Crédito',
    });
    setValue('cst_pis_cof_saida_monof', {
      value: '04',
      label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
    });
    setValue('cst_pis_cof_ent_subst', {
      value: '75',
      label: '75 - Operação de Aquisição por Substituição Tributária',
    });

    setValue('cst_pis_cof_saida_subst', {
      value: '05',
      label: '05 - Operação Tributável por Substituição Tributária',
    });
    setValue('cst_pis_cof_ent_imune', {
      value: '74',
      label: '74 - Operação de Aquisição sem Incidência da Contribuição',
    });
    setValue('cst_pis_cof_saida_imune', {
      value: '08',
      label: '08 - Operação sem Incidência da Contribuição',
    });
    setValue('cst_pis_cof_ent_susp', {
      value: '72',
      label: '72 - Operação de Aquisição com Suspensão',
    });
    setValue('cst_pis_cof_saida_susp', {
      value: '09',
      label: '09 - Operação com Suspensão da Contribuição',
    });
  }, []);

  function changeAba(tab: string) {
    setAba(tab);
  }

  function changeAbaPeriferico(tab: string) {
    setAbaPeriferico(tab);
  }

  const [formRegraLoja, setFormRegraLoja] = useState<IFormRegraLoja>({
    cod_loja: {
      value: { value: -1, label: '' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_st_bc_pis_cof: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_ipi_bc_pis_cof: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_exc_icms_bc_pis_ent: {
      value: false,
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    val_pis: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    val_cofins: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cod_darf_pis: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cod_darf_cofins: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_entrada: {
      value: { label: '', value: '50' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_saida: {
      value: { label: '', value: '01' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_ent_aliq_zero: {
      value: { label: '', value: '73' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_saida_aliq_zero: {
      value: { label: '', value: '06' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_ent_monof: {
      value: { label: '', value: '70' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_saida_monof: {
      value: { label: '', value: '04' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_ent_subst: {
      value: { label: '', value: '75' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_saida_subst: {
      value: { label: '', value: '05' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_ent_imune: {
      value: { label: '', value: '74' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_saida_imune: {
      value: { label: '', value: '08' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_ent_susp: {
      value: { label: '', value: '72' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cst_pis_cof_saida_susp: {
      value: { label: '', value: '09' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },

    tipo_regime: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    tipo_regime_fcp: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    val_desp_operacional: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_trunca_val_deb_icms: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_ignora_st_cf_custo: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    val_margem_venda: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    num_meses_vm: {
      value: 0,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    tipo_integra_frente: {
      value: { label: '', value: -1 },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    cod_interface_carga_pdv: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    des_path_carga_pdv: {
      value: '',
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    des_path_banco_frente: {
      value: '',
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_compacta_arq_pdv: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_enviar_plu_frente: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },

    cod_pessoa_sefaz: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    cod_cc_gare: {
      value: { value: undefined, label: '' },
      isInvalid: false,
      isRequired: false,
    },
    cod_finalizadora_gare: {
      value: { value: undefined, label: '' },
      isInvalid: false,
      isRequired: false,
    },
    num_condicao_gare: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    cod_condicao_gare: {
      value: { value: undefined, label: '' },
      isInvalid: false,
      isRequired: false,
    },
    cod_categoria_gare: {
      value: { value: undefined, label: '' },
      isInvalid: false,
      isRequired: false,
    },
    cod_cc_abertura: {
      value: { value: undefined, label: '' },
      isInvalid: false,
      isRequired: false,
    },
    flg_ignora_tes: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_tes_sem_turno: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_tes_sem_bloq: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    tipo_totalizador_etq_bal: {
      value: { value: 0, label: '' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    qtd_dig_cod_bal: {
      value: { value: 0, label: '' },
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    flg_sem_dig_verif_bal: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    Etiquetas: {
      etiquetas: [],
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    Balancas: {
      balancas: [],
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    tipo_regime_pis_cofins: {
      value: { label: 'Não cumulativo ', value: 0 },
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
  });

  const [formEtiqueta, setFormEtiqueta] = useState<IFormEtiqueta>({
    nome_pc: {
      value: '',
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    cod_parametro_etiqueta: {
      value: undefined,
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    des_parametro: {
      value: '',
      isInvalid: false,
      isRequired: true,
      invalidLabel: undefined,
    },
    porta: {
      value: { label: '', value: -1 },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    linguagem: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    temperatura: {
      value: 10,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    dpi: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
    margem_esquerda: {
      value: 10,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    velocidade: {
      value: -1,
      isInvalid: false,
      isRequired: false,
      invalidLabel: undefined,
    },
    pagina_codigo: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
      invalidLabel: undefined,
    },
  });

  const [formBalanca, setFormBalanca] = useState<IFormBalanca>({
    uuid: {
      value: nanoid(),
      isInvalid: false,
      isRequired: false,
    },
    cod_parametro_balanca: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
      invalidLabel: '',
    },
    cod_interface: {
      value: { label: '', value: -1 },
      isInvalid: true,
      isRequired: true,
      invalidLabel: '',
    },
    des_path: {
      value: '',
      isInvalid: false,
      isRequired: false,
      invalidLabel: '',
    },
    flg_secao_unica: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: '',
    },
    flg_sem_digito_plu: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: '',
    },
    flg_compacta_arq: {
      value: false,
      isInvalid: false,
      isRequired: false,
      invalidLabel: '',
    },
    flg_inativo: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  });

  function handleClearForms() {
    setInitInicializado(false);
    changeAba('geral');
    changeAbaPeriferico('etiquetas');
    reset();
    setValue('tipo_regime_pis_cofins', { label: 'Não cumulativo ', value: 0 });
    setValue('cst_pis_cof_entrada', {
      value: '50',
      label:
        '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
    });
    setValue('cst_pis_cof_saida', {
      value: '01',
      label: '01 - Operação Tributável com Alíquota Básica',
    });
    setValue('cst_pis_cof_ent_aliq_zero', {
      value: '73',
      label: '73 - Operação de Aquisição a Alíquota Zero',
    });

    setValue('cst_pis_cof_saida_aliq_zero', {
      value: '06',
      label: '06 - Operação Tributável a Alíquota Zero',
    });
    setValue('cst_pis_cof_ent_monof', {
      value: '70',
      label: '70 - Operação de Aquisição sem Direito a Crédito',
    });
    setValue('cst_pis_cof_saida_monof', {
      value: '04',
      label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
    });
    setValue('cst_pis_cof_ent_subst', {
      value: '75',
      label: '75 - Operação de Aquisição por Substituição Tributária',
    });

    setValue('cst_pis_cof_saida_subst', {
      value: '05',
      label: '05 - Operação Tributável por Substituição Tributária',
    });
    setValue('cst_pis_cof_ent_imune', {
      value: '74',
      label: '74 - Operação de Aquisição sem Incidência da Contribuição',
    });
    setValue('cst_pis_cof_saida_imune', {
      value: '08',
      label: '08 - Operação sem Incidência da Contribuição',
    });
    setValue('cst_pis_cof_ent_susp', {
      value: '72',
      label: '72 - Operação de Aquisição com Suspensão',
    });
    setValue('cst_pis_cof_saida_susp', {
      value: '09',
      label: '09 - Operação com Suspensão da Contribuição',
    });

    setFormRegraLoja({
      cod_loja: {
        value: { value: -1, label: '' },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      flg_st_bc_pis_cof: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_ipi_bc_pis_cof: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_exc_icms_bc_pis_ent: {
        value: false,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      val_pis: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      val_cofins: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cod_darf_pis: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cod_darf_cofins: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_entrada: {
        value: { label: '', value: '50' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_saida: {
        value: { label: '', value: '01' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_ent_aliq_zero: {
        value: { label: '', value: '73' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_saida_aliq_zero: {
        value: { label: '', value: '06' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_ent_monof: {
        value: { label: '', value: '70' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_saida_monof: {
        value: { label: '', value: '04' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_ent_subst: {
        value: { label: '', value: '75' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_saida_subst: {
        value: { label: '', value: '05' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_ent_imune: {
        value: { label: '', value: '74' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_saida_imune: {
        value: { label: '', value: '08' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_ent_susp: {
        value: { label: '', value: '72' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cst_pis_cof_saida_susp: {
        value: { label: '', value: '09' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },

      tipo_regime: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      tipo_regime_fcp: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      val_desp_operacional: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_trunca_val_deb_icms: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_ignora_st_cf_custo: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      val_margem_venda: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      num_meses_vm: {
        value: 0,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      tipo_integra_frente: {
        value: { label: '', value: -1 },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_interface_carga_pdv: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_path_carga_pdv: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      des_path_banco_frente: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_compacta_arq_pdv: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_enviar_plu_frente: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      cod_pessoa_sefaz: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      cod_cc_gare: {
        value: null,
        isInvalid: false,
        isRequired: false,
      },
      cod_finalizadora_gare: {
        value: null,
        isInvalid: false,
        isRequired: false,
      },
      num_condicao_gare: {
        value: 0,
        isInvalid: false,
        isRequired: false,
      },
      cod_condicao_gare: {
        value: null,
        isInvalid: false,
        isRequired: false,
      },
      cod_categoria_gare: {
        value: null,
        isInvalid: false,
        isRequired: false,
      },
      cod_cc_abertura: {
        value: null,
        isInvalid: false,
        isRequired: false,
      },
      flg_ignora_tes: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_tes_sem_turno: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_tes_sem_bloq: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      tipo_totalizador_etq_bal: {
        value: { value: 0, label: '' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      qtd_dig_cod_bal: {
        value: { value: 0, label: '' },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      flg_sem_dig_verif_bal: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      Etiquetas: {
        etiquetas: [],
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      Balancas: {
        balancas: [],
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      tipo_regime_pis_cofins: {
        value: { label: 'Não cumulativo ', value: 0 },
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
    });
    handleClearFormEtiqueta();
    handleClearFormBalanca();
    setBalancasInsert([]);
    setBalancasUpdate([]);
    setBalancasDelete([]);
  }

  function handleClearFormEtiqueta() {
    setFormEtiqueta({
      nome_pc: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      cod_parametro_etiqueta: {
        value: undefined,
        isInvalid: false,
        isRequired: true,
        invalidLabel: undefined,
      },
      des_parametro: {
        value: '',
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      porta: {
        value: { label: '', value: -1 },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      linguagem: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      temperatura: {
        value: 10,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      dpi: {
        value: { label: '', value: undefined },
        isInvalid: true,
        isRequired: true,
        invalidLabel: undefined,
      },
      margem_esquerda: {
        value: 10,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      velocidade: {
        value: -1,
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
      pagina_codigo: {
        value: { label: '', value: 0 },
        isInvalid: false,
        isRequired: false,
        invalidLabel: undefined,
      },
    });
  }

  function handleClearFormBalanca() {
    setFormBalanca({
      uuid: {
        value: nanoid(),
        isInvalid: false,
        isRequired: false,
      },
      cod_parametro_balanca: {
        value: { label: '', value: undefined },
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
      cod_interface: {
        value: { label: '', value: -1 },
        isInvalid: true,
        isRequired: true,
        invalidLabel: '',
      },
      des_path: {
        value: '',
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
      flg_secao_unica: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
      flg_sem_digito_plu: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
      flg_compacta_arq: {
        value: false,
        isInvalid: false,
        isRequired: false,
        invalidLabel: '',
      },
      flg_inativo: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
    });
  }

  function changeFormRegraLoja(value: IFormRegraLoja) {
    setFormRegraLoja(value);
  }

  function changeFormEtiqueta(value: IFormEtiqueta) {
    setFormEtiqueta(value);
  }

  function changeFormBalanca(value: IFormBalanca) {
    setFormBalanca(value);
  }

  function toggleInitInicializado(value: boolean) {
    setInitInicializado(value);
  }

  function toggleIsUpdate(value: boolean) {
    setIsUpdate(value);
  }

  return (
    <RegraLojaContext.Provider
      value={{
        formRegraLoja,
        changeFormRegraLoja,
        formEtiqueta,
        changeFormEtiqueta,
        handleClearFormEtiqueta,
        formBalanca,
        changeFormBalanca,
        handleClearFormBalanca,
        handleClearForms,
        aba,
        changeAba,
        abaPeriferico,
        changeAbaPeriferico,
        initInicializado,
        toggleInitInicializado,
        toggleIsUpdate,
        isUpdate,
        setFormRegraLoja,
        balancas,
        setBalancas,
        balancasInsert,
        setBalancasInsert,
        balancasUpdate,
        setBalancasUpdate,
        balancasDelete,
        setBalancasDelete,
        formTela,
        tipoTotalizadoresBalanca,
        qntdDigts,
      }}
    >
      {children}
    </RegraLojaContext.Provider>
  );
}

export const useRegraLoja = () => {
  return useContext(RegraLojaContext);
};
